const Validate = () => {
   
    return ( 
        <div>
            <div>
                Validate
                <>
                </>
            </div>
        </div>
    );
}
 
export default Validate;